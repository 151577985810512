<template>
  <div>
    <!-- 志愿者 -->
    <div v-if="detailType == 'volunteer'">
      <div class="volunteer-detail-bottom-right">
        <div class="volunteer-detail-bottom-right-title">推荐志愿者团队</div>
        <div class="volunteer-detail-bottom-right-list">
          <ul class="volunteer-detail-bottom-right-list-ul">
            <li
              class="volunteer-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
                <div class="volunteer-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.cover"
                    alt=""
                  />
                </div>
                <div class="volunteer-detail-bottom-right-list-ul-li-bottom">
                  <div
                    class="volunteer-detail-bottom-right-list-ul-li-bottom-title"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="volunteer-detail-bottom-right-list-ul-li-bottom-people"
                  >
                    {{ item.team_total }}人
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 社团 -->
    <div v-else-if="detailType == 'organization'">
      <div class="organization-detail-bottom-right">
        <div class="organization-detail-bottom-right-title">推荐团队</div>
        <div class="organization-detail-bottom-right-list">
          <ul class="organization-detail-bottom-right-list-ul">
            <li
              class="organization-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
                <div class="organization-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.pic"
                    alt=""
                  />
                </div>
                <div class="organization-detail-bottom-right-list-ul-li-bottom">
                  <div
                    class="organization-detail-bottom-right-list-ul-li-bottom-title"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="organization-detail-bottom-right-list-ul-li-bottom-people"
                  >
                    {{ item.members }}人
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 活动 -->
    <div v-else-if="detailType == 'activity'">
      <div class="activity-detail-bottom-right">
        <div class="activity-detail-bottom-right-title">推荐活动</div>
        <div class="activity-detail-bottom-right-list">
          <ul class="activity-detail-bottom-right-list-ul">
            <li
              class="activity-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
             
                <div class="activity-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.banner"
                    alt=""
                  />
                </div>
                <div class="activity-detail-bottom-right-list-ul-li-bottom">
                  <div
                    class="activity-detail-bottom-right-list-ul-li-bottom-title"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="activity-detail-bottom-right-list-ul-li-bottom-people"
                  >
                    <div
                      class="activity-detail-bottom-right-list-ul-li-bottom-people-address"
                    >
                      <img
                        class="activity-detail-bottom-right-list-ul-li-bottom-people-address-icon"
                        src="https://image.bookgo.com.cn/web_culture/activity/activity_address.png"
                        alt=""
                      />

                      <div>{{ item.address }}</div>
                    </div>
                    <div
                      class="activity-detail-bottom-right-list-ul-li-bottom-people-date"
                    >
                      <img
                        class="activity-detail-bottom-right-list-ul-li-bottom-people-date-icon"
                        src="https://image.bookgo.com.cn/web_culture/activity/activity_time.png"
                        alt=""
                      />

                      <div>{{ item.active_time }}</div>
                    </div>
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 培训 -->
    <div v-else-if="detailType == 'culture'">
      <div class="culture-detail-bottom-right">
        <div class="culture-detail-bottom-right-title">推荐文化培训课程</div>
        <div class="culture-detail-bottom-right-list">
          <ul class="culture-detail-bottom-right-list-ul">
            <li
              class="culture-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
                <div class="culture-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.cover"
                    alt=""
                  />
                </div>
                <div class="culture-detail-bottom-right-list-ul-li-bottom">
                  <div
                    class="culture-detail-bottom-right-list-ul-li-bottom-title"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="culture-detail-bottom-right-list-ul-li-bottom-people"
                  >
                    {{ item.chapter_count }}课
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 点单 -->
    <div v-if="detailType == 'order'">
      <div class="order-detail-bottom-right">
        <div class="order-detail-bottom-right-title">推荐点单</div>
        <div class="order-detail-bottom-right-list">
          <ul class="order-detail-bottom-right-list-ul">
            <li
              class="order-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
                <div class="order-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.cover"
                    alt=""
                  />
                </div>
                <div class="order-detail-bottom-right-list-ul-li-bottom">
                  <div
                    class="order-detail-bottom-right-list-ul-li-bottom-title"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="order-detail-bottom-right-list-ul-li-bottom-type-box"
                  >
                    <div
                      class="order-detail-bottom-right-list-ul-li-bottom-type-box-type"
                    >
                      {{ item.deliverer }}
                    </div>
                    <div
                      class="order-detail-bottom-right-list-ul-li-bottom-type-box-status"
                    >
                      {{ item.status_text }}
                    </div>
                  </div>
                  <div class="order-detail-bottom-right-list-ul-li-bottom-host">
                    {{ item.name }}
                  </div>
                  <div
                    class="order-detail-bottom-right-list-ul-li-bottom-summary"
                  >
                    {{ item.content | deleteHtmlTag }}
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 直播 -->
    <div v-if="detailType == 'live'">
      <div class="live-detail-bottom-right">
        <div class="live-detail-bottom-right-title">推荐直播</div>
        <div class="live-detail-bottom-right-list">
          <ul class="live-detail-bottom-right-list-ul">
            <li
              class="live-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
                <div class="live-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.cover"
                    alt=""
                  />
                </div>
                <div class="live-detail-bottom-right-list-ul-li-bottom">
                  <div class="live-detail-bottom-right-list-ul-li-bottom-title">
                    {{ item.title }}
                  </div>
                  <div
                    class="live-detail-bottom-right-list-ul-li-bottom-type-box"
                  >
                    <div
                      class="live-detail-bottom-right-list-ul-li-bottom-type-box-status"
                      v-if="item.status == 2"
                    >
                      <div>正在直播</div>
                      <img
                        class="live-detail-bottom-right-list-ul-li-bottom-type-box-status-icon"
                        src="https://image.bookgo.com.cn/web_culture/live/live_living.png"
                        alt=""
                      />
                    </div>
                    <div
                      class="live-detail-bottom-right-list-ul-li-bottom-type-box-type"
                      v-else-if="item.status == 1"
                    >
                      直播预告
                    </div>
                    <div
                      class="live-detail-bottom-right-list-ul-li-bottom-type-box-type"
                      v-else-if="item.status == 3"
                    >
                      精彩回顾
                    </div>
                  </div>
                  <div class="live-detail-bottom-right-list-ul-li-bottom-host">
                    主办单位：{{ item.sponsor }}
                  </div>
                  <div
                    class="live-detail-bottom-right-list-ul-li-bottom-summary"
                  >
                    直播简介：{{ item.content }}
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 数字资源 -->
    <div v-if="detailType == 'resources'">
      <div class="resources-detail-bottom-right">
        <div class="resources-detail-bottom-right-title">推荐资源</div>
        <div class="resources-detail-bottom-right-list">
          <ul class="resources-detail-bottom-right-list-ul">
            <li
              class="resources-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeSource(item)"
            >
                <div class="resources-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.main_pic"
                    alt=""
                  />
                </div>
                <div class="resources-detail-bottom-right-list-ul-li-bottom">
                  <div
                    class="resources-detail-bottom-right-list-ul-li-bottom-title"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="resources-detail-bottom-right-list-ul-li-bottom-summary"
                  >
                    {{ item.des | deleteHtmlTag }}
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 非遗 -->
    <div v-if="detailType == 'intangible'">
      <div class="intangible-detail-bottom-right">
        <div class="intangible-detail-bottom-right-title">推荐非遗</div>
        <div class="intangible-detail-bottom-right-list">
          <ul class="intangible-detail-bottom-right-list-ul">
            <li
              class="intangible-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
                <div class="intangible-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.main_pic"
                    alt=""
                  />
                </div>
                <div class="intangible-detail-bottom-right-list-ul-li-bottom">
                  <div
                    class="intangible-detail-bottom-right-list-ul-li-bottom-title"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="intangible-detail-bottom-right-list-ul-li-bottom-summary"
                  >{{item.des |deleteHtmlTag}}</div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 推荐书籍 -->
    <div v-if="detailType == 'resources_t'">
      <div class="resources_t-detail-bottom-right">
        <div class="resources_t-detail-bottom-right-title">推荐书籍</div>
        <div class="resources_t-detail-bottom-right-list">
          <ul class="resources_t-detail-bottom-right-list-ul">
            <li
              class="resources_t-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeBook(item)"
            >
              <div class="resources_t-detail-bottom-right-list-ul-li-card">
                
                  {{ index + 1 }}. {{ item.name }}

              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 资讯 -->
    <div v-if="detailType == 'news'">
      <div class="news-detail-bottom-right">
        <div class="news-detail-bottom-right-title">推荐资讯</div>
        <div class="news-detail-bottom-right-list">
          <ul class="news-detail-bottom-right-list-ul">
            <li
              class="news-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
<!-- :href="`/official/aid4002/news/detail?id=${item.id}` -->
              <div v-if="item.is_link==0">
                <div class="news-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.cover"
                    alt=""
                  />
                </div>
                <div class="news-detail-bottom-right-list-ul-li-bottom">
                  <div class="news-detail-bottom-right-list-ul-li-bottom-title">
                    {{ item.title }}
                  </div>
                  <div
                    class="news-detail-bottom-right-list-ul-li-bottom-summary"
                  >
                    {{ item.summary }}
                  </div>
                </div>
              </div>


              <a v-if="item.is_link==1" :href="item.jump_url">
                <div class="news-detail-bottom-right-list-ul-li-cover">
                  <img
                          style="width: 100%; height: 100%"
                          :src="item.cover"
                          alt=""
                  />
                </div>
                <div class="news-detail-bottom-right-list-ul-li-bottom">
                  <div class="news-detail-bottom-right-list-ul-li-bottom-title">
                    {{ item.title }}
                  </div>
                  <div
                          class="news-detail-bottom-right-list-ul-li-bottom-summary"
                  >
                    {{ item.summary }}
                  </div>
                </div>
              </a>

            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 推荐场馆 -->
    <div v-else-if="detailType == 'map_detail'">
      <div class="map-detail-bottom-right">
        <div class="map-detail-bottom-right-title">推荐场馆</div>
        <div class="map-detail-bottom-right-list">
          <ul class="map-detail-bottom-right-list-ul">
            <li
              class="map-detail-bottom-right-list-ul-li"
              v-for="(item, index) in list"
              :key="index"
              @click="changeInfo(item)"
            >
            <!-- `/official/aid4002/map/detail?id=${item.id}` -->
                <div class="map-detail-bottom-right-list-ul-li-cover">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.pic"
                    alt=""
                  />
                </div>
                <div class="map-detail-bottom-right-list-ul-li-bottom">
                  <div class="map-detail-bottom-right-list-ul-li-bottom-title">
                    {{ item.name }}
                  </div>
                  <div class="map-detail-bottom-right-list-ul-li-bottom-people">
                    <div
                      class="map-detail-bottom-right-list-ul-li-bottom-people-address"
                    >
                      <img
                        class="map-detail-bottom-right-list-ul-li-bottom-people-address-icon"
                        src="https://image.bookgo.com.cn/web_culture/activity/activity_address.png"
                        alt=""
                      />

                      <div>{{ item.address }}</div>
                    </div>
                    <div
                      class="map-detail-bottom-right-list-ul-li-bottom-people-date"
                    >
                      <img
                        class="map-detail-bottom-right-list-ul-li-bottom-people-date-icon"
                        src="https://image.bookgo.com.cn/web_culture/activity/activity_time.png"
                        alt=""
                      />

                      <div>{{ item.work_time }}</div>
                    </div>
                  </div>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailType: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: null,
    },
  },
  methods:{
    changeInfo(item) {
      console.log(item);
      this.$emit('change-EVENT',item.id)
      // this.$router.push(`detail?id=${item.id}`)
    },
    changeBook(item) {
      console.log(item);
      this.$emit('change-EVENT',item.id)
      this.$router.push(`tdetail?id=${item.id}`)
    },
    changeSource(item) {
      console.log(item);
      if(item.category_id == 3 ) {
        this.$emit("change-EVENT1",item.id)
        this.$router.push(`edetail?id=${item.id}`)
      } else {
        this.$emit("change-EVENT2",item.id)
        this.$router.push(`tdetail?id=${item.id}`)
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/c_detail.scss";
</style>